<template>
  <!--begin::Shipments index for Superadmin-->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/envios-primary.svg"
      />
      <div>
        {{ $t("SHIPMENTS.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row py-6 align-items-center">
        <div class="col-md-6 d-flex d-row align-items-center">
          <h5 class="mr-3 mb-0 font-weight-bolder" style="white-space: pre">
            {{ $t("SHIPMENTS.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>
        <div class="col-md-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>
        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="downloadExcel"
            :disabled="DownloadingExcel"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("SHIPMENTS.EXCEL_TITLE") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.STATUS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByShipmentStatusID"
            @change="searchShipments(false)"
          >
            <option
              v-for="s in StatusOptions"
              :key="'S' + s.ShipmentStatusID"
              :value="s.ShipmentStatusID"
              class="text-primary"
            >
              {{ s.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("SHIPMENTS.CREATION_DATE") }}
          </label>
          <v-text-field
            class="form-control bg-white"
            :value="formatDateRange(FilterByCreatedAtFrom, FilterByCreatedAtTo)"
            readonly
            @click="openFilterDateRangeDialog('CreatedAt')"
          />
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("SHIPMENTS.FINISHED_AT") }}
          </label>
          <v-text-field
            class="form-control bg-white"
            :value="
              formatDateRange(FilterByFinishedAtFrom, FilterByFinishedAtTo)
            "
            readonly
            @click="openFilterDateRangeDialog('FinishedAt')"
          />
        </div>

        <div class="col-lg-4 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::FilterDateRangeDialog dialog-->
    <v-dialog
      v-model="FilterDateRangeDialog.Show"
      :return-value="FilterDateRangeDialog.Value"
      persistent
      width="320px"
    >
      <v-date-picker
        id="FilterDateRangeDialog"
        v-model="FilterDateRangeDialog.Value"
        color="tertiary"
        scrollable
        range
        :locale="DateLocale"
        :first-day-of-week="DateFirstDayOfWeek"
      >
        <div class="row button-row">
          <button
            class="btn btn-pill btn-white button-form button-form-primary mr-9"
            style="min-width: 125px"
            @click="cancelFilterDateRangeDialog"
          >
            {{ $t("GENERIC.CANCEL") }}
          </button>
          <button
            class="btn btn-pill btn-tertiary button-form"
            style="min-width: 125px"
            @click="saveFilterDateRangeDialog"
          >
            {{ $t("GENERIC.OK") }}
          </button>
        </div>
      </v-date-picker>
    </v-dialog>
    <!--end::FilterDateRangeDialog dialog-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr :class="item.ShipmentStatusID === 13 ? 'bg-primary-o-30' : ''">
          <td>
            {{ item.ShipmentID }}
          </td>
          <td>
            {{ formatDate(item.CreatedAt) }}
          </td>
          <td>
            {{ item.Transporter.FiscalName }}
            <br />
            {{ $t("COMPANIES.FISCAL_ID_SHORT") }}:
            {{ item.Transporter.FiscalID }}
          </td>
          <td>
            {{ item.Sender.FiscalName }}
            <br />
            {{ $t("COMPANIES.FISCAL_ID_SHORT") }}:
            {{ item.Sender.FiscalID }}
          </td>
          <td>
            <p
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder m-0"
            >
              {{ $t("OFFERS.COMMON.THERE").toUpperCase() }}:
            </p>
            <p
              v-for="(v, index) in item.There.Vehicles"
              :key="'V_' + item.ShipmentID + '_There_' + index"
              class="m-0"
            >
              <strong style="white-space: nowrap;">
                {{ v.Brand + ", " + v.Model + "." }}
              </strong>
              <br />
              {{ v.Plate }}
            </p>
            <p
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder m-0"
            >
              {{ $t("OFFERS.COMMON.BACK").toUpperCase() }}:
            </p>
            <p
              v-for="(v, index) in item.Back.Vehicles"
              :key="'V_' + item.ShipmentID + '_Back_' + index"
              class="m-0"
            >
              <strong style="white-space: nowrap;">
                {{ v.Brand + ", " + v.Model + "." }}
              </strong>
              <br />
              {{ v.Plate }}
            </p>
          </td>
          <td>
            {{ getProvinceName(item.OriginAddress.ProvinceID) }}
          </td>
          <td>
            {{ getProvinceName(item.DestinationAddress.ProvinceID) }}
          </td>
          <td>
            {{ getSubscriptionTypeName(item.Sender.SubscriptionTypeID) }}
          </td>
          <td>
            {{
              item.PriceAtFinish > -1
                ? item.PriceAtFinish.toFixed(2).replace(".", ",") +
                  " " +
                  item.Currency
                : " - "
            }}
          </td>
          <td>
            {{ item.CommissionPercent > -1 ? item.CommissionPercent : " - " }}
          </td>
          <td>
            {{ $t("SHIPMENT_STATUS_NAMES_BY_ID." + item.ShipmentStatusID) }}
          </td>
          <td>
            {{ formatDate(item.FinishedAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchShipments(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::Shipments index for Superadmin-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import ShipmentStatusService from "@/core/services/api/v2/shipmentstatus.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "ShipmentsSuperadminIndex",
  computed: {
    ...mapGetters(["isAuthenticated", "companyTypesList"])
  },
  data() {
    return {
      TableHeaders: [
        { text: this.$t("SHIPMENTS.ID"), value: "ShipmentID", width: 120 },
        { text: this.$t("SHIPMENTS.CREATION_DATE"), value: "CreatedAt" },
        {
          text: this.$t("SHIPMENTS.TRANSPORTER"),
          value: "Transporter.FiscalName",
          width: 180
        },
        {
          text: this.$t("SHIPMENTS.SENDER"),
          value: "Sender.FiscalName",
          width: 180
        },
        {
          text: this.$t("OFFERS.COMMON.VEHICLES"),
          value: "Vehicles",
          width: 220
        },
        {
          text: this.$t("OFFERS.COMMON.ORIGIN"),
          value: "OriginAddress.ProvinceID",
          width: 180
        },
        {
          text: this.$t("OFFERS.COMMON.DESTINATION"),
          value: "DestinationAddress.ProvinceID",
          width: 180
        },
        {
          text: this.$t("COMPANIES.SUBSCRIPTION_TYPE"),
          value: "Sender.SubscriptionTypeID"
        },
        { text: this.$t("SHIPMENTS.PRICE_AT_FINISH"), value: "PriceAtFinish" },
        {
          text: this.$t("SHIPMENTS.COMMISSION_PERCENT"),
          value: "CommissionPercent"
        },
        {
          text: this.$t("OFFERS.COMMON.STATUS"),
          value: "ShipmentStatusID",
          width: 120
        },
        { text: this.$t("SHIPMENTS.FINISHED_AT"), value: "FinishedAt" }
      ],
      options: {},
      StatusOptions: [],
      SubscriptionTypeOptions: [],
      SubscriptionTypeNames: [],
      ProvinceNames: [],
      SortBy: "Code",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByShipmentStatusID: 0,
      FilterByCreatedAtFrom: "",
      FilterByCreatedAtTo: "",
      FilterByFinishedAtFrom: "",
      FilterByFinishedAtTo: "",
      FilterDateRangeDialog: {
        Show: false,
        WhichFilter: "",
        Value: []
      },
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ],
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      DownloadingExcel: false
    };
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadStatusOptions();
    this.loadSubscriptionTypeOptions();
    this.loadProvinceNames();
  },

  methods: {
    loadStatusOptions() {
      this.StatusOptions = [];
      this.StatusOptions.push({ ShipmentStatusID: 0, Name: " " });

      ShipmentStatusService.listAll().then(response => {
        response.forEach(s => {
          this.StatusOptions.push(s);
        });
      });
    },

    loadSubscriptionTypeOptions() {
      this.SubscriptionTypeOptions = [];
      this.SubscriptionTypeOptions.push({ SubscriptionTypeID: 0, Name: " " });

      this.SubscriptionTypeNames = [];
      this.SubscriptionTypeNames[0] = " ";

      SubscriptionTypeService.listAll(this.companyTypesList.ID_SENDER).then(
        response => {
          response.forEach(st => {
            this.SubscriptionTypeOptions.push(st);
            this.SubscriptionTypeNames[st.SubscriptionTypeID] = st.Name;
          });
        }
      );
    },

    loadProvinceNames() {
      this.ProvinceNames = [];
      this.ProvinceNames[0] = " ";

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchShipments(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(ShipmentService.getCachedFiltersBySuperadmin()).forEach(
        f => {
          this["FilterBy" + f[0]] = f[1];

          if (!anyFilterNotEmpty) {
            anyFilterNotEmpty = parseInt(f[1]) > 0;
          }
        }
      );

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchShipments(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      ShipmentService.searchBySuperadmin(
        this.SearchText,
        this.FilterByShipmentStatusID,
        this.FilterByCreatedAtFrom,
        this.FilterByCreatedAtTo,
        this.FilterByFinishedAtFrom,
        this.FilterByFinishedAtTo,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Shipments;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchShipments(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchShipments(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchShipments(false);
    },

    changeItemsCurrentPage() {
      this.searchShipments(true);
    },

    openFilterDateRangeDialog(whichFilter) {
      this.FilterDateRangeDialog.WhichFilter = whichFilter;

      let oldValueFrom = "";
      let oldValueTo = "";

      if (whichFilter === "CreatedAt") {
        oldValueFrom = this.FilterByCreatedAtFrom;
        oldValueTo = this.FilterByCreatedAtTo;
      } else if (whichFilter === "FinishedAt") {
        oldValueFrom = this.FilterByFinishedAtFrom;
        oldValueTo = this.FilterByFinishedAtTo;
      }

      this.FilterDateRangeDialog.Value = [oldValueFrom, oldValueTo];
      this.FilterDateRangeDialog.Show = true;
    },

    saveFilterDateRangeDialog() {
      if (this.FilterDateRangeDialog.Value.length === 2) {
        let newValues = this.FilterDateRangeDialog.Value.sort();
        let whichFilter = this.FilterDateRangeDialog.WhichFilter;

        if (whichFilter === "CreatedAt") {
          this.FilterByCreatedAtFrom = newValues[0];
          this.FilterByCreatedAtTo = newValues[1];
        } else if (whichFilter === "FinishedAt") {
          this.FilterByFinishedAtFrom = newValues[0];
          this.FilterByFinishedAtTo = newValues[1];
        }
      }

      this.FilterDateRangeDialog.Show = false;
      this.searchShipments(false);
    },

    cancelFilterDateRangeDialog() {
      // Reset picker to old date
      let whichFilter = this.FilterDateRangeDialog.WhichFilter;

      let oldDates = [];
      if (whichFilter === "CreatedAt") {
        oldDates = [this.FilterByCreatedAtFrom, this.FilterByCreatedAtTo];
      } else if (whichFilter === "FinishedAt") {
        oldDates = [this.FilterByFinishedAtFrom, this.FilterByFinishedAttTo];
      }

      this.FilterDateRangeDialog.Value = oldDates;
      this.FilterDateRangeDialog.Show = false;
    },

    cleanFilters() {
      this.FilterByShipmentStatusID = 0;
      this.FilterByCreatedAtFrom = "";
      this.FilterByCreatedAtTo = "";
      this.FilterByFinishedAtFrom = "";
      this.FilterByFinishedAtTo = "";
      this.ShowFilters = false;
      this.searchShipments(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchShipments(false);
      }, 600);
    },

    getProvinceName(provinceID) {
      let name = this.ProvinceNames[provinceID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    getSubscriptionTypeName(stID) {
      let name = this.SubscriptionTypeNames[stID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    formatDate(date) {
      return date.length > 0 ? moment(date).format(this.DateFormatLang) : "-";
    },

    formatDateRange(dateFrom, dateTo) {
      if (dateFrom === "" && dateTo === "") {
        return "";
      } else if (dateFrom === dateTo) {
        return this.formatDate(dateFrom);
      } else {
        return this.formatDate(dateFrom) + " - " + this.formatDate(dateTo);
      }
    },

    downloadExcel() {
      // Disable download button, because this may take a while
      this.DownloadingExcel = true;

      ShipmentService.downloadExcelBySuperadmin(
        this.SearchText,
        this.FilterByShipmentStatusID,
        this.FilterByCreatedAtFrom,
        this.FilterByCreatedAtTo,
        this.FilterByFinishedAtFrom,
        this.FilterByFinishedAtTo,
        this.SortBy,
        this.SortDesc
      ).then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t("SHIPMENTS.TITLE_INDEX");
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.DownloadingExcel = false;
      });
    }
  }
};
</script>
